import React from 'react';
import classNames from 'classnames';
import Excellent from './svgs/trustpilot-excellent.svg';
import styles from './styles.module.scss';
interface TrustpilotMicrostarRatingProps {
  className?: string;
}
const TrustpilotMicrostarRating = ({
  className
}: TrustpilotMicrostarRatingProps) => {
  return <Excellent className={classNames(styles.rating, className)} title="Accommodation for Students is rated Excellent on Trustpilot" data-sentry-element="Excellent" data-sentry-component="TrustpilotMicrostarRating" data-sentry-source-file="index.tsx" />;
};
export default TrustpilotMicrostarRating;