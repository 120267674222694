import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

import Link from '../../../atoms/Link'
import LazyImage from '../../../atoms/LazyImage'
import TrustpilotMicrostarRating from '../../../atoms/TrustpilotMicrostarRating'

import Facebook from '../icons/facebook.svg'
import X from '../icons/x.svg'
import Instagram from '../icons/instagram.svg'
import Tiktok from '../icons/tiktok.svg'
import Youtube from '../icons/youtube.svg'

const currentYear = new Date().getFullYear()

const Footer = ({ className, extraLink, variant, ...props }) => {
  const footerClasses = classNames('site-footer', className, {
    'site-footer--dark': variant === 'dark',
    'site-footer--gray': variant === 'gray',
    'site-footer--warm-gray': variant === 'warm-gray',
  })

  return (
    <footer className={footerClasses} {...props} data-testid="footer-uk">
      <div className="site-footer__inner">
        <div className="site-footer__flex">
          <nav
            className="site-footer__nav site-footer__nav--public"
            aria-label="Public links"
          >
            <ul className="site-footer__nav__list">
              <li className="site-footer__nav__item">
                <Link className="site-footer__nav__link" to="/about-us">
                  About us
                </Link>
              </li>
              <li className="site-footer__nav__item">
                <Link className="site-footer__nav__link" to="/contact-us">
                  Contact us
                </Link>
              </li>
            </ul>
            <ul className="site-footer__nav__list">
              <li className="site-footer__nav__item">
                <Link className="site-footer__nav__link" to="/student-blog">
                  Student blog
                </Link>
              </li>
              <li className="site-footer__nav__item">
                <Link className="site-footer__nav__link" to="/student-guides">
                  Student guides
                </Link>
              </li>
              <li className="site-footer__nav__item">
                <Link className="site-footer__nav__link" to="/uk-universities">
                  UK Universities
                </Link>
              </li>
            </ul>
          </nav>
          <nav
            className="site-footer__nav site-footer__nav--business"
            aria-label="Business links"
          >
            <ul className="site-footer__nav__list">
              <li className="site-footer__nav__item">
                <Link
                  className="site-footer__nav__link"
                  href="/landlord-admin/login"
                >
                  Landlords
                </Link>
              </li>
              <li className="site-footer__nav__item">
                <Link className="site-footer__nav__link" to="/advertise">
                  Advertise
                </Link>
              </li>
              <li className="site-footer__nav__item">
                <Link
                  className="site-footer__nav__link"
                  to="/student-landlord-guides"
                >
                  Landlord blog
                </Link>
              </li>
              <li className="site-footer__nav__item">
                <Link
                  className="site-footer__nav__link"
                  to="/research-and-reports"
                >
                  Research
                </Link>
              </li>
            </ul>
            <ul
              className="site-footer__nav__list"
              aria-label="Terms & Conditions"
            >
              <li className="site-footer__nav__item">
                <Link className="site-footer__nav__link" to="/terms">
                  Terms and Conditions
                </Link>
              </li>
              <li className="site-footer__nav__item">
                <Link className="site-footer__nav__link" href="/privacy">
                  Privacy Policy
                </Link>
              </li>
            </ul>
            {extraLink && (
              <ul className="site-footer__nav__list">{extraLink}</ul>
            )}
          </nav>

          <nav className="site-footer__nav site-footer__nav--social">
            <ul className="site-footer__nav__list" aria-label="Social">
              <li className="site-footer__nav__item site-footer__nav__item--inline">
                <Link
                  className="site-footer__nav__link"
                  href="https://www.facebook.com/accommodation4students"
                  target="_blank"
                  rel="noopener"
                >
                  <span className="u-hidden-visually">Find us on Facebook</span>
                  <Facebook className="site-footer__nav__icon" />
                </Link>
              </li>
              <li className="site-footer__nav__item site-footer__nav__item--inline">
                <Link
                  className="site-footer__nav__link"
                  href="https://www.instagram.com/accommodation4students/ "
                  target="_blank"
                  rel="noopener"
                >
                  <span className="u-hidden-visually">
                    Follow us on Instagram
                  </span>
                  <Instagram className="site-footer__nav__icon" />
                </Link>
              </li>
              <li className="site-footer__nav__item site-footer__nav__item--inline">
                <Link
                  className="site-footer__nav__link"
                  href="https://x.com/accomforstudent"
                  target="_blank"
                  rel="noopener"
                >
                  <span className="u-hidden-visually">Post us on X</span>
                  <X className="site-footer__nav__icon" />
                </Link>
              </li>
              <li className="site-footer__nav__item site-footer__nav__item--inline">
                <Link
                  className="site-footer__nav__link"
                  href="https://www.tiktok.com/@accommodationforstudents"
                  target="_blank"
                  rel="noopener"
                >
                  <span className="u-hidden-visually">Follow us on TikTok</span>
                  <Tiktok className="site-footer__nav__icon" />
                </Link>
              </li>{' '}
              <li className="site-footer__nav__item site-footer__nav__item--inline">
                <Link
                  className="site-footer__nav__link"
                  href="https://www.youtube.com/@accomforstudents"
                  target="_blank"
                  rel="noopener"
                >
                  <span className="u-hidden-visually">Watch us on Youtube</span>
                  <Youtube className="site-footer__nav__icon" />
                </Link>
              </li>
              <li className="site-footer__nav__item--trustpilot">
                <Link
                  href="https://uk.trustpilot.com/review/www.accommodationforstudents.com"
                  target="_blank"
                  rel="noopener"
                >
                  <TrustpilotMicrostarRating className="site-footer__trustpilot-logo" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="site-footer__bottom">
          <small className="site-footer__copy">
            {`© ${currentYear} Accommodation for Students`}
          </small>
          <div className="site-footer__student-minds">
            <span className="student-minds__support-text">
              We proudly support{' '}
            </span>
            <Link
              href="https://www.studentminds.org.uk/"
              target="_blank"
              rel="noopener nofollow"
            >
              <span className="u-hidden-visually">Student Minds</span>
              <LazyImage
                alt="Student Minds logo"
                dataSrc="https://afs-assets-public.s3.eu-west-2.amazonaws.com/charity/student-minds-logo.png"
              />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  extraLink: PropTypes.node,
  variant: PropTypes.oneOf(['dark', 'gray', 'warm-gray']),
}

export default Footer
