import React, { useState, useEffect, HTMLProps } from 'react';
import classNames from 'classnames';
import 'lazysizes';
import './styles.scss';
export interface LazyImageProps extends HTMLProps<HTMLImageElement> {
  className?: string;
  alt: string;
  dataSrc: string;
  dataSizes?: string;
  dataSrcSet?: string;
  errorImageSrc?: string;
}
const LazyImage = ({
  className,
  alt,
  dataSrc,
  dataSizes = 'auto',
  dataSrcSet,
  errorImageSrc,
  ...props
}: LazyImageProps) => {
  const [mounted, setMounted] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const imageClasses = mounted ? classNames('lazyload', className).trim() : classNames('not-mounted', className).trim();
  if (isError) {
    return <img className={className} key={`${dataSrc}-error`} src={errorImageSrc} alt="Broken image link" {...props} />;
  }
  return <img className={imageClasses} alt={alt} data-src={dataSrc} data-sizes={dataSizes} data-srcset={dataSrcSet || undefined} onError={() => {
    if (errorImageSrc) {
      setIsError(true);
    }
  }} {...props} data-sentry-component="LazyImage" data-sentry-source-file="index.tsx" />;
};
export default LazyImage;