import React from 'react';
import { useCountryContext } from '../../../contexts/countryContext';
import FooterInternational from './FooterInternational';
import FooterUk from './FooterUk';
interface FooterProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  extraLink?: React.ReactNode;
  variant?: 'dark' | 'gray' | 'warm-gray';
}
const Footer = ({
  className,
  extraLink,
  variant,
  ...props
}: FooterProps) => {
  // @ts-ignore
  const {
    isInternational
  } = useCountryContext();
  return isInternational ? <FooterInternational className={className} extraLink={extraLink} variant={variant} {...props} /> : <FooterUk className={className} extraLink={extraLink} variant={variant} {...props} />;
};
export default Footer;